

:-moz-placeholder {
  color: #acb6c8 !important;
}

::-moz-placeholder {
  color: #acb6c8 !important;
  opacity: 1;
}

::-webkit-input-placeholder {
  color: #acb6c8;
}

:-ms-input-placeholder {
  color: #acb6c8;
}

::-moz-focus-inner {
  padding: 0 !important;
  border: 0 !important;
}

apple-pay-button {
  --apple-pay-button-width: 270px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}